import React, {useState} from "react"
import Swiper from "react-id-swiper"
import {Navigation} from "swiper/dist/js/swiper.esm"
import Button from "Accessories/Button/Button"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import "./BasicSliderSection.scss"
import { graphql } from "gatsby";

const BasicSliderSection = ({images}) => {
  const settings = {
    slidesPerView: 1,
    grabCursor: false,
    lazy: true,
    breakpointsInverse: true,
    breakpoints: {
      1024: {
        allowTouchMove: false,
      }
    }
  }

  const [currentSlideCount, setCurrentSlideCount] = useState(1)
  const [swiper, updateSwiper] = useState(null)
  const totalSlides = images.length

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
      setCurrentSlideCount(swiper.activeIndex + 1)
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
      setCurrentSlideCount(swiper.activeIndex + 1)
    }
  }

  return (
    <div className="section section-swiper mt-2">
      <Swiper
        getSwiper={updateSwiper}
        modules={[Navigation]}
        {...settings}
      >
        {images.map(
          (projectImage) =>
            projectImage.image && projectImage.image.localFile && (
              <div
                className="project-slide"
                key={projectImage.image.localFile.childImageSharp.fluid.originalName}
              >
                {
                  projectImage.image.localFile.childImageSharp.fluid && (
                    <Img
                      fluid={projectImage.image.localFile.childImageSharp.fluid}
                    />
                  )
                }
                {
                  projectImage.caption && <span className="image-caption d-inline-block text-sm">{projectImage.caption}</span>
                }
              </div>
            )
        )}
      </Swiper>
      {
        totalSlides > 1 && (
          <div className="d-flex justify-content-between justify-content-md-center align-items-center swiper-info-wrapper">
            <span className="item-count text-md d-inline-block">{`${currentSlideCount} — ${totalSlides}`}</span>
            <div className="swiper-controls-wrapper">
              <div className="swiper-controls">
                <Button
                  className={`swiper-prev ${currentSlideCount === 1 ? "is-deactivated" : ""}`}
                  text="Prev"
                  onClick={goPrev}
                />
                <Button
                  className={`swiper-next ${currentSlideCount === totalSlides ? "is-deactivated" : ""}`}
                  text="Next"
                  onClick={goNext}
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

BasicSliderSection.propTypes = {
  settings: PropTypes.instanceOf(Object), // eslint-disable-line react/forbid-prop-types,
}

BasicSliderSection.defaultProps = {
  settings: {
    grabCursor: false,
    slidesPerView: "auto",
    loop: false,
  }
}

export const query = graphql`
  fragment BasicSliderSectionProjectFragment on WordPressAcf_basic_slider_section {
    images {
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1646) {
              ...GatsbyImageSharpFluid_noBase64
              originalName
            }
          }
        }
      }
      caption
    }
  }
  fragment BasicSliderSectionPageFragment on WordPressAcf_basic_slider_section {
    images {
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1646) {
              ...GatsbyImageSharpFluid_noBase64
              originalName
            }
          }
        }
      }
      caption
    }
  }
`

export default BasicSliderSection
