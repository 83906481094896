import React from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import IntroText from "Layout/IntroText/IntroText"
import RelatedProjects from "Layout/Projects/RelatedProjects/RelatedProjects"
import FlexibleContent from "Layout/FlexibleContent/FlexibleContent"

const Residency = ({data, location}) => {

  const {page, projects, optionsPage} = data
  const gI = optionsPage.edges[0].node.global_data.generic_images

  const residencyProjects = projects.edges.filter(
    project => project.node.categories_names.filter(
      item => item.taxonomy === "research_track"
    ).length > 0 && project.node.categories_names.filter(
      item => item.taxonomy === "research_track"
    )[0].name.toLowerCase() === page.title.toLowerCase()
  )

  return (
    <MainLayout location={location}>
      <IntroText intro={page.acf.intro_text} headline={page.title} genericImages={gI} classes="pt-5" />
      {page.acf.content_page && page.acf.content_page.length && (
        <div className="section-flexible-content section pb-5 container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-6 text-lg offset-md-4 offset-lg-6 mb-5">
                <FlexibleContent content={page.acf.content_page} location={location} pageName="PageTemplate" />
              </div>
            </div>
          </div>
        </div>
      )}
      <RelatedProjects projects={residencyProjects} headline="Residency in Situated Philosophy Projects" />
    </MainLayout>
  )
}

export const query = graphql`
  query residencyPageQuery {
    page: wordpressPage(wordpress_id: {eq: 190}) {
      title
      slug
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
        content_page {
          ... on WordPressAcf_slider {
            id
            images {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1646) {
                      ...GatsbyImageSharpFluid_noBase64
                      originalName
                    }
                  }
                }
              }
              caption
            }
          }
          ... on WordPressAcf_text {
            id
            text
          }
        }
      }
    }
    projects: allWordpressWpProjects {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 684) {
                  src
                  width
                  height
                  originalName
                }
              }
            }
          }
          categories_names {
            name
            taxonomy
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Residency
