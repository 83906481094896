import React from "react"
import "./TextSection.scss"
import { graphql } from "gatsby";

const TextSection = ({
  text
}) => {

  return (
    <div className="section section-text mt-2">
      <div dangerouslySetInnerHTML={{ __html: text }} className="mb-2 description-container" />
    </div>
  )
}

export const query = graphql`
  fragment TextSectionProjectFragment on WordPressAcf_text_section {
    id
    text
  }
  fragment TextSectionPageFragment on WordPressAcf_text_section {
    id
    text
  }
`

export default TextSection
